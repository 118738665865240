import CheckBoxOutlineBlankOutlined from "@mui/icons-material/CheckBoxOutlineBlankOutlined"
import CheckBoxOutlined from "@mui/icons-material/CheckBoxOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined"
import { Box } from "@mui/material"
import { Timeline, TimelineGroup } from "@react-admin/ra-audit-log"
import {
  CreateInDialogButton,
  EditInDialogButton,
} from "@react-admin/ra-form-layout"
import {
  Datagrid,
  IfCanAccess,
  Show,
  SimpleShowLayout,
} from "@react-admin/ra-rbac"
import { snakeCase } from "lodash-es"
import { useMemo } from "react"
import {
  BooleanField,
  DateField,
  EditButton,
  FileField,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TopToolbar,
  UpdateButton,
  WithRecord,
  WrapperField,
  useGetList,
  useGetRecordId,
  useResourceContext,
  useTranslate,
} from "react-admin"
import { useOrganization } from "../../hooks/useOrganizationId"
import { ThumbnailField } from "../Components/ThumbnailField"
import { ToolNoteForm } from "../ToolNotes/ToolNoteForm"
import { primaryKeys } from "../dataProvider"
import { RecordTimelineItem } from "./RecordTimelineItem"
import { TransferButton } from "./TransferButton"
import { inactiveStatuses, labelFromInactiveStatus } from "./inactiveStatuses"
import { labelFromToolState } from "./toolStates"
// const StatusEditToolbar = () => (
//   <Toolbar>{/* <SaveButton alwaysEnable /> */}</Toolbar>
// )
import { ServiceIntervalField } from "../Components/ServiceIntervalField"
import { TransferQuantityInput } from "./Transfer/TransferQuantityInput"

const RecordTimeline = ({ children }) => {
  const resource = useResourceContext()
  const primaryKey = primaryKeys.get(resource)
  const id = useGetRecordId()
  const { data, isLoading } = useGetList("events", {
    filter: {
      resource,
      [`payload->data->>${primaryKey}`]: id,
    },
    pagination: { page: 1, perPage: 25 },
    sort: { field: "date", order: "DESC" },
  })

  return (
    <Timeline isLoading={isLoading} records={data}>
      {children}
    </Timeline>
  )
}

const ToolShowActions = () => {
  return (
    <TopToolbar>
      <EditInDialogButton label="resources.tools.actions.edit_status">
        <SimpleForm
          // TODO: File a bug report for this not working
          // toolbar={<StatusEditToolbar />}
          toolbar={false}
        >
          <TransferQuantityInput source="quantity" />
          <SelectInput
            source="inactive_status"
            choices={[{ id: "ACTIVE", name: "Active" }, ...inactiveStatuses]}
            parse={(value) => (value === "ACTIVE" ? null : value)}
            format={(value) => (value === null ? "ACTIVE" : value)}
          />
          <SaveButton />
        </SimpleForm>
      </EditInDialogButton>
      <UpdateButton
        label="ra.action.audit"
        icon={<NoteAltOutlinedIcon />}
        data={{
          state: "AUDITING",
          inactive_status: null,
          recipient_id: null,
        }}
      />
      <TransferButton />
      <UpdateButton
        label="ra.action.active"
        icon={<CloseOutlinedIcon />}
        data={{
          state: "ACTIVE",
          inactive_status: null,
          recipient_id: null,
        }}
      />
      <IfCanAccess action="edit">
        <EditButton />
      </IfCanAccess>
    </TopToolbar>
  )
}

export const ToolShow = () => {
  const translate = useTranslate()
  const { data: organization } = useOrganization()
  const { id_column } = organization || {}
  //TODO: move this into the app
  const finalId = useMemo(() => snakeCase(id_column), [id_column])
  if (!organization) return null
  return (
    <Show actions={<ToolShowActions />}>
      <SimpleShowLayout>
        <WrapperField label="Photos">
          <ThumbnailField source="tool_photo" sx={{ display: "inline-flex" }} />
          <ThumbnailField
            source="label_photo"
            sx={{ display: "inline-flex" }}
          />
          <ThumbnailField
            source="additional_photo_0"
            sx={{ display: "inline-flex" }}
          />
          <ThumbnailField
            source="additional_photo_1"
            sx={{ display: "inline-flex" }}
          />
          <ThumbnailField
            source="additional_photo_2"
            sx={{ display: "inline-flex" }}
          />
          <ThumbnailField
            source="additional_photo_3"
            sx={{ display: "inline-flex" }}
          />
          <ThumbnailField
            source="additional_photo_4"
            sx={{ display: "inline-flex" }}
          />
        </WrapperField>
        <TextField source={finalId} label="Item ID#" />
        <TextField source="name" />
        <ReferenceField reference="crew_members" source="sender_id" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          reference="crew_members"
          source="recipient_id"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="projects" source="project_id" link="show" />
        <FunctionField
          source="state"
          render={(record, source) => labelFromToolState(record[source])}
        />
        <FunctionField
          source="inactive_status"
          render={(record, source) => labelFromInactiveStatus(record[source])}
        />
        <TextField source="quantity" />
        <BooleanField
          source="is_consumable"
          FalseIcon={CheckBoxOutlineBlankOutlined}
          TrueIcon={CheckBoxOutlined}
        />
        <ReferenceField
          reference="org_categories"
          source="category"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="brands" source="brand" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" />
        <TextField source="purchase_order" />
        <TextField source="serial_number" />
        <FunctionField
          source="Utilization"
          render={({ utilization_unit, track_utilization }) =>
            track_utilization && (
              <NumberField
                source="utilization"
                options={{
                  style: "unit",
                  unit: utilization_unit,
                }}
              />
            )
          }
        />
        <NumberField
          source="value"
          options={{ style: "currency", currency: "USD" }}
        />
        <TextField source="quantity_value" />
        <TextField source="rental_rent" />
        <FileField source="attachments" src="src" title="title" />
        <DateField source="updated_at" />
        <DateField source="created_at" />
        <ReferenceManyField
          reference="tool_notes"
          target="tool_id"
          label="Notes"
          filter={{ "deleted_at@is": "NULL" }}
          sort={{ field: "created_at", order: "DESC" }}
        >
          <WithRecord
            render={({ organization_id, tool_id }) => (
              <CreateInDialogButton record={{ organization_id, tool_id }}>
                <ToolNoteForm hideTool />
              </CreateInDialogButton>
            )}
          />
          <Datagrid
            bulkActionButtons={false}
            isRowSelectable={() => false}
            rowClick={false}
          >
            <WrapperField textAlign="left">
              <Box sx={{ flexDirection: "column", display: "flex" }}>
                <ThumbnailField
                  source="photo"
                  sx={{ display: "inline-flex" }}
                />
                <TextField source="note" />
                <br />
                <ReferenceField
                  reference="crew_members"
                  source="crew_member_id"
                  link="show"
                />
                <DateField showTime source="created_at" />
              </Box>
            </WrapperField>
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          label={translate("resources.tool_services.name", {
            smart_count: 100,
          })}
          reference="tool_services"
          target="tool_id"
          filter={{
            "select@": "*,crew_members!inner(name)",
            organization_id: organization.id,
            "crew_members.organization_id": organization.id,
            "deleted_at@is": "NULL",
          }}
          sort={{ field: "due_date", order: "DESC" }}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField
              source="service_template_id"
              reference="service_templates"
            />
            <TextField
              source="crew_members.name"
              label="resources.tool_services.fields.crew_member_id"
            />
            <DateField source="due_date" />
            {/* <DateField source="completed_at" /> */}
            <ReferenceField
              source="service_template_id"
              reference="service_templates"
              label="Recurring" // TODO: i18n
            >
              <ServiceIntervalField source="service_interval" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          label={translate("resources.service_records.name", {
            smart_count: 100,
          })}
          reference="service_records"
          target="tool_id"
          filter={{
            "select@": "*,crew_members!inner(name)",
            organization_id: organization.id,
            "crew_members.organization_id": organization.id,
            "deleted_at@is": "NULL",
          }}
          sort={{ field: "completed_at", order: "DESC" }}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField
              emptyText="None"
              source="tool_service_id"
              reference="tool_services"
            >
              <ReferenceField
                source="service_template_id"
                reference="service_templates"
              />
            </ReferenceField>
            <TextField source="notes" />
            <TextField source="cost" />
            <FileField
              label="resources.service_records.fields.attachment"
              source="attachment.src"
              target="_blank"
              title="attachment.title"
            />
            <FunctionField
              source="checklist"
              render={(record, source) => {
                const checklist = record[source]
                return checklist
                  ? checklist
                      .map((item) => item.step + (item.complete ? " ✓" : " ✗"))
                      .join(", ")
                  : ""
              }}
            />
            <TextField
              source="crew_members.name"
              label="resources.service_records.fields.crew_member_id"
            />
            <DateField showTime showDate source="completed_at" />
          </Datagrid>
        </ReferenceManyField>
        <RecordTimeline>
          <TimelineGroup>
            <RecordTimelineItem />
          </TimelineGroup>
        </RecordTimeline>
      </SimpleShowLayout>
    </Show>
  )
}
