import { useDisclosure } from "@dwarvesf/react-hooks"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined"
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined"
import { Dialog, DialogTitle } from "@mui/material"
import { BulkUpdateFormButton } from "@react-admin/ra-form-layout"
import { List, ListActions } from "@react-admin/ra-rbac"
import { snakeCase } from "lodash-es"
import { useMemo } from "react"
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  BulkDeleteButton,
  BulkUpdateButton,
  Button,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  FunctionField,
  NullableBooleanInput,
  NumberField,
  Pagination,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ResourceContextProvider,
  SelectColumnsButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useCreate,
  useListContext,
  useResourceContext,
  useUnselectAll,
} from "react-admin"
import { NIL } from "uuid"
import {
  useOrganization,
  useOrganizationId,
} from "../../hooks/useOrganizationId"
import { ThumbnailField } from "../Components/ThumbnailField"
import { Dashboard } from "../Dashboard"
import { CrewMemberInput } from "./CrewMemberInput"
import { ProjectInput } from "./ProjectInput"
import { inactiveStatuses, labelFromInactiveStatus } from "./inactiveStatuses"
import { labelFromToolState, toolStates } from "./toolStates"
import { useToolsExporter } from "./toolsExporter"

const ToolFilters = (crewMemberFilter, organization_id) => [
  <ReferenceArrayInput
    key="assigned"
    reference="crew_members"
    source="sender_id@in"
    filter={crewMemberFilter}
  >
    <AutocompleteArrayInput
      source="name"
      optionText="name"
      filterToQuery={(query) => ({
        "name@ilike": query,
      })}
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    key="project"
    reference="projects"
    source="project_id@in"
    sort={{ field: "updated_at", order: "DESC" }}
    filter={{
      "organization_id@in": [NIL, organization_id],
      "deleted_at@is": "NULL",
    }}
    perPage={100}
  >
    <AutocompleteArrayInput
      source="project_name"
      optionText="project_name"
      filterToQuery={(query) => ({ "project_name@ilike": query })}
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    key="category"
    reference="org_categories"
    source="category@in"
    filter={{ organization_id }}
    sort={{ field: "tool_count", order: "DESC" }}
    perPage={100}
  >
    <AutocompleteArrayInput
      source="name"
      optionText="name"
      filterToQuery={(query) => ({ "name@ilike": query })}
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    key="brand"
    reference="brands"
    source="brand@in"
    sort={{ field: "name", order: "ASC" }}
    perPage={100}
  >
    <AutocompleteArrayInput
      source="name"
      optionText="name"
      filterToQuery={(query) => ({ "name@ilike": query })}
    />
  </ReferenceArrayInput>,
  <SelectInput source="state" choices={toolStates} />,
  <SelectInput source="inactive_status" choices={inactiveStatuses} />,
  <NullableBooleanInput source="is_consumable" label="Consumables" />,
]

const ToolListActions = () => {
  const toolsExport = useToolsExporter()
  return (
    <TopToolbar sx={{ alignItems: "center" }}>
      <SelectColumnsButton style={{ marginTop: 5 }} />
      {/* {TODO: File a bug, the RBAC exporter doesn't work} */}
      <ListActions exporter={false} />
      <ExportButton exporter={toolsExport} maxResults={10_000} />
    </TopToolbar>
  )
}

export function ToolList() {
  const [organization_id] = useOrganizationId()
  const { data } = useOrganization()
  const enable_auto_toolroom_manager = useMemo(
    () => data?.enable_auto_toolroom_manager ?? false,
    [data?.enable_auto_toolroom_manager]
  )
  const crewFilter = useMemo(
    () =>
      enable_auto_toolroom_manager
        ? { "deleted_at@is": "NULL", "role@neq": "support", organization_id }
        : {
            "crew_member_id@neq": NIL,
            "deleted_at@is": "NULL",
            "role@neq": "support",
            organization_id,
          },
    [enable_auto_toolroom_manager, organization_id]
  )
  const toolFilters = useMemo(
    () => ToolFilters(crewFilter, organization_id),
    [crewFilter, organization_id]
  )
  if (!organization_id) return null
  return (
    <List
      sort={{ field: "updated_at", order: "DESC" }}
      filters={[
        <TextInput
          key="search_terms"
          label="Search"
          // We are doing this rather than wfts so we can get partial matches on the final word
          source="search_terms@fts"
          alwaysOn
        />,
        ...toolFilters,
      ]}
      filter={{
        "deleted_at@is": null,
        organization_id,
        "tool_photo@not.is": null,
      }}
      hasCreate
      empty={<Dashboard />}
      actions={<ToolListActions />}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <ToolDatagrid
        bulkActionButtons={
          <TopToolbar>
            <BulkUpdateButton
              label="ra.action.audit"
              icon={<NoteAltOutlinedIcon />}
              data={{
                state: "AUDITING",
                inactive_status: null,
                recipient_id: null,
              }}
            />
            <BulkUpdateFormButton
              label="ra.action.transfer"
              icon={<ArrowForwardIcon />}
            >
              <SimpleForm>
                <CrewMemberInput
                  source="sender_id"
                  organization_id={organization_id}
                />
                <ProjectInput
                  source="project_id"
                  organization_id={organization_id}
                />
              </SimpleForm>
            </BulkUpdateFormButton>
            <CreateToolServiceButton />
            <BulkUpdateButton
              label="ra.action.active"
              icon={<CloseOutlinedIcon />}
              data={{
                state: "ACTIVE",
                inactive_status: null,
                recipient_id: null,
              }}
            />
            <BulkUpdateFormButton icon={<EditOutlinedIcon />}>
              <SimpleForm>
                <ReferenceInput
                  key="category"
                  reference="org_categories"
                  source="category"
                  filter={{ organization_id, "deleted_at@is": null }}
                  // It's not respecting the sort order from the view; why not?
                  sort={{ field: "name", order: "ASC" }}
                  perPage={100}
                >
                  <AutocompleteInput
                    fullWidth
                    source="name"
                    optionText="name"
                    filterToQuery={(query) => ({
                      "name@ilike": query,
                    })}
                  />
                </ReferenceInput>
                <ReferenceInput
                  key="brand"
                  reference="brands"
                  source="brand"
                  sort={{ field: "name", order: "ASC" }}
                  perPage={100}
                >
                  <AutocompleteInput
                    fullWidth
                    source="name"
                    optionText="name"
                    filterToQuery={(query) => ({ "name@ilike": query })}
                  />
                </ReferenceInput>
                <SelectInput
                  source="inactive_status"
                  choices={[
                    { id: "ACTIVE", name: "Active" },
                    ...inactiveStatuses,
                  ]}
                  parse={(value) => (value === "ACTIVE" ? null : value)}
                  format={(value) => (value === null ? "ACTIVE" : value)}
                />
                <TextInput source="value" />
                <BooleanInput source="enable_procore_daily_log" />
              </SimpleForm>
            </BulkUpdateFormButton>
            <BulkDeleteButton />
          </TopToolbar>
        }
      />
    </List>
  )
}

function CreateToolServiceButton(props) {
  const [organization_id] = useOrganizationId()
  const resource = useResourceContext(props)
  const { selectedIds } = useListContext(props)
  const unselectAll = useUnselectAll(resource)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [create] = useCreate()
  const handleCreate = async (record) => {
    await Promise.all(
      selectedIds.map((tool_id) => {
        const data = { ...record, tool_id, organization_id }
        return create("tool_services", { data })
      })
    )
    onClose()
    unselectAll()
  }
  return (
    <>
      <Button
        label="Schedule Service"
        onClick={onOpen}
        startIcon={<ScheduleOutlinedIcon />}
      />
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Schedule Service </DialogTitle>
        <ResourceContextProvider value="tool_services">
          <SimpleForm onSubmit={handleCreate}>
            <ReferenceInput
              reference="service_templates"
              source="service_template_id"
              filter={{
                organization_id,
                "deleted_at@is": "NULL",
              }}
            >
              <AutocompleteInput
                fullWidth
                source="name"
                optionText="name"
                filterToQuery={(query) => ({
                  "name@ilike": query,
                })}
              />
            </ReferenceInput>
            <CrewMemberInput
              source="crew_member_id"
              organization_id={organization_id}
            />
            <DateInput source="due_date" />
          </SimpleForm>
        </ResourceContextProvider>
      </Dialog>
    </>
  )
}

const omit = [
  "description",
  "value",
  "quantity_value",
  "purchase_order",
  "serial_number",
  "created_at",
  "last_service_date",
  "brand",
  // "state",
  // "last_service_description",
  "next_service_date",
  "enable_procore_daily_log",
  // "next_service_description",
]
export const ToolDatagrid = (props) => {
  const { data: organization } = useOrganization()
  const { id_column } = organization || {}
  //TODO: move this into the app
  const finalId = useMemo(() => snakeCase(id_column), [id_column])
  if (!organization) return null

  return (
    <DatagridConfigurable
      {...props}
      // mutationMode="undoable"

      // createForm={<ToolRowForm />}
      // editForm={<ToolRowForm />}
      // showForm={<ToolShow />}
      omit={omit}
      rowClick="show"
    >
      <ThumbnailField
        source="primary_photo"
        src="src"
        width={50}
        height={50}
        sx={{
          "& img": { maxWidth: 50, maxHeight: 50, objectFit: "contain" },
        }}
      />
      <TextField source={finalId} label="Item ID#" />
      <TextField source="name" />
      <TextField source="category" />
      <ReferenceField reference="brands" source="brand" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField reference="projects" source="project_id" link="show">
        <TextField source="project_name" />
      </ReferenceField>
      <ReferenceField reference="crew_members" source="sender_id" link="show">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="quantity" sx={{ maxWidth: 10 }} />
      <TextField source="purchase_order" />
      <TextField source="serial_number" />
      <FunctionField
        source="state"
        render={(record, source) => labelFromToolState(record[source])}
      />
      <FunctionField
        source="inactive_status"
        render={(record, source) => labelFromInactiveStatus(record[source])}
      />
      <TextField source="description" />
      <TextField source="value" />
      <TextField source="quantity_value" />
      <BooleanField source="enable_procore_daily_log" FalseIcon={null} />
      <DateField source="last_service_date" showTime />
      <DateField source="next_service_date" showTime />
      <DateField source="updated_at" showTime />
      <DateField source="created_at" showTime />
    </DatagridConfigurable>
  )
}
